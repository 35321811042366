<template>
  <div>
    <b-row>
      <b-col>
        <b-card>
          <b-row>
            <b-col lg="2" sm="6">
              <b-button variant="relief-success" v-b-modal.modal-store>
               Adicionar
                <feather-icon icon="PlusIcon" />
              </b-button>
            </b-col>
            <b-col>
              <!-- <b-form-input
                placeholder="Pesquisar"
                v-model="search"
                debounce="800"
                size="sm"
                type="search"
              /> -->
            </b-col>
          </b-row>
          <b-overlay :show="isLoading" opacity="0.70" rounded="sm">
          <b-table-simple
              striped
              stacked="md"
              class="mt-2 responsive text-center"
              id="tablePlans"
              :item="fetchPlans"
              :per-page="perPage"
              :current-page="currentPage"
            >
              <b-thead>
                <b-tr class="text-center">
                  <b-th>Nome</b-th>
                  <b-th>Valor</b-th>
                  <b-th>Data de registro</b-th>
                  <b-th>Ação</b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr :key="indextr" v-for="(tr, indextr) in plans">
                  <b-td>
                    {{tr.name}}
                  </b-td>
                  <b-td>
                    {{tr.value | dinheiro}}
                  </b-td>
                  <b-td>
                    {{tr.created_at | dateFormat }}
                  </b-td>
                  <b-td>
                    <b-button
                      v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                      variant="flat-warning"
                      class="btn-icon rounded-circle"
                      v-b-modal.modal-update
                      @click="showDetail(tr.id)"
                    >
                      <feather-icon icon="Edit2Icon" />
                    </b-button>

                    <b-button
                      v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                      variant="flat-danger"
                      class="btn-icon rounded-circle"
                      v-b-modal.modal-delete
                      @click="showDetail(tr.id)"
                    >
                      <feather-icon icon="Trash2Icon" />
                    </b-button>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
            <b-pagination
              @change="handlePageChange"
              v-model="currentPage"
              :per-page="perPage"
              :total-rows="totalRows"
              aria-controls="tablePlans"
              class="mt-1"
            />
          </b-overlay>
        </b-card>
      </b-col>
    </b-row>
    <!-- Modal de delete -->
    <b-modal
      id="modal-delete"
      ok-variant="danger"
      modal-class="modal-danger"
      ref="modal-delete"
      centered
      title="Deletar"
    >
      <b-card-text>
        Você está preste a deletar este vídeo do sistema:
        <hr>
        <p> <b>Nome: </b>{{plansModel ? plansModel.name : ''}} </p>
        <p> <b>Valor: </b>{{plansModel ? plansModel.value : '' | dinheiro}}</p>
        <hr>
        Por favor, confirme a exclusão antes de prosseguir.
        <b-form-checkbox
          id="checkbox-1"
          @change="newDeleteStatus"
          v-model="DeleteStatus"
          name="checkbox-1"
          value="accepted"
          unchecked-value="null"
        >
          Concordo em excluir esta mensagem
        </b-form-checkbox>
      </b-card-text>
      <template #modal-footer>
        <b-col>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class="mt-2"
            variant="outline-danger"
            block
            :disabled="DeleteStatus == null"
            @click="deletePlan(plansModel.id)"
          >
            Deletar
          </b-button>
        </b-col>
      </template>
    </b-modal>
    <!-- Modal de store -->
    <b-modal
      id="modal-store"
      modal-class="modal-success"
      ref="modal-store"
      centered
      title="Cadastrar"
    >
      <b-card-text>
        <label> Nome do Plano </label>
        <b-form-input
          placeholder="Nome do Plano"
          v-model="dataPlan.name"
          id="name"
        />
        <label> Valor do Plano </label>
        <b-form-input
          type="number"
          placeholder="Valor do Plano"
          v-model="dataPlan.value"
          id="value"
        />
      </b-card-text>
      <template #modal-footer>
         <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="success"
          class="mr-1"
          @click="storePlan"
        >
          Cadastrar
        </b-button>
      </template>
    </b-modal>

    <!-- Modal de editar -->
    <b-modal
      id="modal-update"
      ok-variant="warning"
      modal-class="modal-warning"
      ref="modal-update"
      centered
      title="Edição"
    >
      <b-card-text>
        Você está preste a editar este Plano do sistema:
        <hr>
      </b-card-text>
      <div v-if="plansModel">
        <label class="mt-1"> Novo Nome </label>
        <b-form-input
          placeholder="Novo Nome"
          id="name"
         :value="updatePlan.name = plansModel.name "
         @change="newNamePlan"
        >
          {{plansModel.name}}
        </b-form-input>
        <label class="mt-1"> Novo Valor </label>
        <b-form-input
          placeholder="Novo Valor"
          id="value"
          :value="updatePlan.value = plansModel.value "
          @change="newValuePlan"
        >
          {{plansModel.value}}
        </b-form-input>
      </div>
      <div v-else>
        Plano não encontrado
      </div>
      <hr>
      <template #modal-footer>
        <b-col>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class="mt-2"
            variant="outline-warning"
            block
            :value="updatePlan.id = plansModel.id"
            @click="updatePlans"
          >
            Editar
          </b-button>
        </b-col>
      </template>
    </b-modal>
  </div>
</template>
<script>
import { BCard } from "bootstrap-vue";
import { mapActions, mapState } from "vuex";
import Ripple from "vue-ripple-directive";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      showSearch: true,
      isLoading: true,
      search: null,
      perPage: 10,
      currentPage: 1,
      totalRows: null,

      DeleteStatus: null,

      plansModel: null,

      id_delete: null,

      dataPlan: {
        name: null,
        value: null
      },

      updatePlan: {
        id: null,
        name: null,
        value: null
      },
    };
  },
  computed: {
    ...mapState("plans", ["plans"]),
  },
  methods: {
     ...mapActions("plans", ["store", "update"]),
    storePlan() {
      this.store({
        ...this.dataPlan,
      })
      .then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Cadastrado com sucesso!',
            icon: 'ThumbsUpIcon',
            text: 'Operação executada com sucesso.',
            variant: 'success'
          },
        })   
      })
      .finally(() =>{
        this.fetchPlans()
        this.$refs['modal-store'].hide()
      });
    },
    updatePlans() {
      this.update({
        ...this.updatePlan,
      })
      .then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Atualizado com sucesso!',
            icon: 'Edit2Icon',
            text: 'Operação executada com sucesso.',
            variant: 'warning'
          },
        })   
      })
      .finally(() =>{
        this.fetchPlans()
        this.$refs['modal-update'].hide()
      });
    },
    deletePlan(id) { 
      this.isLoading = true;
      let data = id; 
      this.$store.dispatch("plans/delete", data)
      .then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Excluído com sucesso!',
            icon: 'Trash2Icon',
            text: 'Operação executada com sucesso.',
            variant: 'danger'
          },
        })
        this.isLoading = false;
        this.DeleteStatus = null;  
      })
      .catch(err => { 
        console.error(err); 
      })
      .finally(() =>{
        this.fetchPlans()
        this.$refs['modal-delete'].hide()
      });
    },
    fetchPlans(paginated = true, page = 1) {
      this.isLoading = true;
      this.$store
        .dispatch("plans/fetchPlans", {
          paginated: paginated,
          page: page,
          generic: this.search,
          perPage: this.perPage,
        })
        .then((response) => {
          this.totalRows = response.total;
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    showDetail(id){
      this.plansModel = this.$store.getters['plans/getPlansById'](id)
    },
    handlePageChange(value) {
      this.fetchPlans(true, value);
    },
    newDeleteStatus(value){
      this.DeleteStatus = value;
    },

    newNamePlan(value){
      this.updatePlan.name = value;
    },

    newValuePlan(value){
      this.updatePlan.value = value;
    },

  },
  created() {
    this.fetchPlans();
  },
  watch: {
     search(val) {
      this.currentPage = 1;
      this.fetchPlans(true, 1);
    },

  },
};
</script>
